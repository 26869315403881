.App {
  background-color: white;
}

.header-cont {
  background-color: #fff7ea;
}

.subheader-cont {
  background-color: #fff7ea;
}

.logo-cont-header {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: fit-content;
  padding-bottom: 2vh;
  padding-top: 4vh;
}

.nav-item {
  padding: 1.5vh;
  color: #43bc94;
  font-size: large;
  font-weight: 600;
  text-decoration: none;
}

.nav-item:hover {
  text-decoration: underline;
}

.nav-cont {
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #e8fdff;
  text-align: center;
}

.row-1 {
  padding: 1vh;
  padding-top: 6vh;
}

.row-2 {
  padding-top: 4vh;
  padding-bottom: 4vh;
  text-align: center;
}

.row-3 {
  padding: 1vh;
}

.row-4 {
  padding: 2vh;
  padding-bottom: 8vh;
}

.footer-cont {
  background-color: #fff7ea;
  padding: 4%;
  align-items: center;
}

.subtitles {
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.videoContainer {
  max-width: 50%;
  padding-bottom: 4vh;
  margin: auto;
}
